import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Contact: React.FC = () => {
  return (
    <div className="container mt-5">
      <div className="row">
        {/* Contact Information Section */}
        <div className="col-md-6">
          <h2 className="mb-4">İletişim Bilgileri</h2>

          <div className="mb-4">
            <ul className="list-unstyled">
              <li className="mb-3">
                <i className="bi bi-telephone-fill me-2" style={{ fontSize: '1.25rem', color: '#007bff' }}></i>
                <strong>Phone:</strong> +90 532 061 5015
              </li>
              <li className="mb-3">
                <i className="bi bi-envelope-fill me-2" style={{ fontSize: '1.25rem', color: '#dc3545' }}></i>
                <strong>Email:</strong> <a href="mailto:arca.murat@example.com">arcamurat@molpack.com.tr</a>
              </li>
            </ul>
          </div>

        </div>
        
        {/* WhatsApp Contact Section */}
        <div className="col-md-6 d-flex align-items-center justify-content-center">
          <div className="text-center">
            <h2 className="mb-4">WhatsApp ile iletişime geç</h2>
            <a 
              href="https://wa.me/905320615015" 
              target="_blank" 
              rel="noopener noreferrer"
              className="d-block"
            >
              <img 
                src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" 
                alt="WhatsApp Logosu" 
                style={{ width: '150px', height: '150px' }}
              />
            </a>
            <p className="mt-3">WhatsApp üzerinden iletişime geçmek için ikona tıklayın.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
