import React from 'react';
import logo2 from './molhalat-logo.png';
import './App.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './screens/Home';
import Header from './components/Header';
import Contact from './screens/Contact';
import Inventory from './screens/Inventory';
import Reports from './screens/reports';

import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';

import { I18n } from 'aws-amplify/utils';
import { Authenticator, translations, ThemeProvider, defaultTheme } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import NavBarHeader from './ui-components/NavBarHeader';
import SoldItems from './screens/sold_Items';

import Recaptcha from "react-google-recaptcha";



Amplify.configure(awsconfig);

// Add custom language support
I18n.putVocabularies(translations);
I18n.setLanguage('tr');

I18n.putVocabularies({
  'tr': {
    'Password is hidden': 'Şifre gizli',
    'Enter your Username': 'Kullanıcı adınızı girin', 
    'Your passwords must match': 'Şifreleriniz eşleşmelidir',
  }
});

// Custom Authenticator Theme
const theme = {
  ...defaultTheme,
  name: 'custom-theme',
  tokens: {
    colors: {
      background: {
        primary: { value: '#f0f0f5' }, // Background for the whole page
        secondary: { value: '#ffffff' }, // Card or container background
      },
      brand: {
        primary: {
          10: { value: '#ff6b6b' }, // Primary brand color (for buttons, etc.)
        },
      },
    },
    components: {
      button: {
        primary: {
          backgroundColor: { value: '#ff6b6b' },
          color: { value: '#fff' },
          _hover: { backgroundColor: { value: '#ff5252' } },
        },
      },
      authenticator: {
        container: { maxWidth: '450px', padding: '2rem', borderRadius: '10px' }, // Adjust the form size and padding
      },
    },
  },
};

function App() {
  return (
    <React.StrictMode>
      <ThemeProvider>
        <Authenticator
          hideSignUp
          variation="modal"
          components={{
            Header() {
              return (
                <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                  <img src={logo2} alt="Logo" style={{ width: '300px', marginBottom: '10px' }} />
                  <h1>MolStok</h1>
                </div>
              );
            },
            Footer(){
              return (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  <p>© 2024 MOLHALAT</p>
                </div>
              );
            }
          }}
        >
          {({ signOut, user }) => (
            <main>
              <Router>
                <Header />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/inventory" element={<Inventory />} />
                  <Route path="/sold" element={<SoldItems />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/reports" element={<Reports />} />
                </Routes>
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  <button onClick={signOut} style={{ padding: '10px 20px', backgroundColor: '#ff6b6b', border: 'none', color: '#fff', borderRadius: '5px' }}>
                    Çıkış Yap, {user ? user.username : ''}
                  </button>
                </div>
              </Router>
            </main>
          )}
        </Authenticator>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default App;


/* 



*/

