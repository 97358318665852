import React from "react";
import logo from "../molhalat-logo.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Ensure Bootstrap's JS is included

function Header() {
    return (
        <div className="menubar">
            <nav className="navbar navbar-expand-lg navbar-fixed-top navbar-default">
                <div className="container">
                    <a className="navbar-brand" href="/">
                        <img src={logo} alt="Welcome to inventory management" className="d-inline-block align-top" />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <a className="nav-link" href="/">Ana Menü</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/inventory">Envanter</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/contact">İletişim</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Header;
