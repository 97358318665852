import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table, Button, Form, FormControl, Modal, Spinner, Image } from 'react-bootstrap';
import { FetchService } from '../services/FetchService';

interface SoldItem {
    sku: string;
    isim: string;
    musteriAdi: string;
    sevkNo: string;
    sevkTarih: string;
    soldDate: string;
}

const fetchService = new FetchService("https://6pecywccp1.execute-api.eu-central-1.amazonaws.com");

const SoldItems: React.FC = () => {
    const [soldItems, setSoldItems] = useState<SoldItem[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [labelUrl, setLabelUrl] = useState<string | null>(null);
    const [showLabelModal, setShowLabelModal] = useState(false);

    // Ref to the label image for printing
    const labelRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const fetchItems = async () => {
            setIsLoading(true);
            try {
                const data = await fetchService.getItems('sold/items');
                setSoldItems(data);
            } catch (error) {
                console.error('Failed to fetch sold items:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchItems();
    }, []);

    // Function to retrieve the label and show the modal
    const handleRetrieveLabel = async (sku: string) => {
        try {
            const response = await fetchService.getItem(`labels/${sku}`);
            setLabelUrl(response.label_url); // Assuming the label URL is returned in the API response
            setShowLabelModal(true);
        } catch (error) {
            console.error('Failed to retrieve label:', error);
        }
    };

    // Print the label by opening the print window for the label image
    const handlePrint = () => {
        if (labelRef.current) {
            const printContents = labelRef.current.innerHTML;
            const originalContents = document.body.innerHTML;
            document.body.innerHTML = printContents;
            window.print();
            document.body.innerHTML = originalContents;
            window.location.reload();
        }
    };

    const filteredItems = soldItems.filter(
        (item) =>
            item.isim.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.sku.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.musteriAdi.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="container mt-4">
            <h2>Satılan Ürünler</h2>

            <div className="mb-4">
                <Form className="d-flex">
                    <FormControl
                        type="search"
                        placeholder="Satılan Ürün Ara"
                        className="me-2"
                        aria-label="Search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <Button variant="outline-success">Ara</Button>
                </Form>
            </div>

            {isLoading ? (
                <div className="text-center my-4">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <div className="table-responsive">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Ürün adı</th>
                                <th>SKU</th>
                                <th>Müşteri Adı</th>
                                <th>Sevkiyat No</th>
                                <th>Sevkiyat Tarihi</th>
                                <th>Satış Tarihi</th>
                                <th>Eylemler</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredItems.map((item) => (
                                <tr key={item.sku}>
                                    <td>{item.isim}</td>
                                    <td>{item.sku}</td>
                                    <td>{item.musteriAdi}</td>
                                    <td>{item.sevkNo}</td>
                                    <td>{item.sevkTarih}</td>
                                    <td>{item.soldDate}</td>
                                    <td>
                                        <Button variant="primary" size="sm" className="me-2" onClick={() => handleRetrieveLabel(item.sku)}>
                                            Etiketi Görüntüle
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            )}

            {/* Label Modal */}
            <Modal show={showLabelModal} onHide={() => setShowLabelModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Etiket</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div ref={labelRef}>
                        {labelUrl ? (
                            <Image src={labelUrl} fluid />
                        ) : (
                            <p>Etiket yüklenemedi.</p>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowLabelModal(false)}>
                        Kapat
                    </Button>
                    <Button variant="primary" onClick={handlePrint}>
                        Yazdır
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default SoldItems;
